<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
			<div class="page_1_center_box">
				<div class="cen-text">
					ZT8350 Fiscal Terminal
				</div>
				<div class="sub-text">
					Financial grade, high security
				</div>
			</div>
		</div>
		<div class="part-2">
			<h2>Anti-dismantle Various encryption algorithms</h2>
            <h2>Assemble financial-grade encrypted pinpad</h2>
		</div>
		<div class="part-3 part-3-1">
			<div class="part-3-main">Data protection</div>
			<div class="part-3-sub">Large-capacity non-volatile memory backup</div>
		</div>
        <div class="part-3 part-3-2">
			<div class="part-3-main">Strong expansion power <br> and rich functions</div>
			<div class="part-3-sub">USB x1、Cash drawer x1、RJ45 Ethernet x1、RS232x1、Power x1</div>
		</div>
		<div class="part-4">
			<div class="part-4-head">
				<h1>Multi payments</h1>
				<h3>Online and offline integration to meet full-featured payment</h3>
			</div>
			<div class="part-4-show">
				<div class="screen">
					<div class="col-1">
						<div class="scence">
							<label>Receipt issuing</label>
							<img src="../../assets/images/prods/zt8350/ps1.png" />
						</div>
					</div>
					<div class="col-2">
						<div class="scence">
							<label>Vending</label>
							<img src="../../assets/images/prods/zt8350/ps2.png" />
						</div>
						<div class="scence">
							<label>Retail</label>
							<img src="../../assets/images/prods/zt8350/ps3.png" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<ProdSpec :data="zt8350.data" />

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
import { zt8350 } from '@/views/products/spec/spec_en';
import ProdSpec from "@/components/ProdSpec.vue";
import PageNav from "@/components/PageNav.vue"
import PageBot from "@/components/PageBottom.vue"
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import {
	resetRem
} from '@/all_js/all';
import {
	clickEffect
} from "@/all_js/click";
export default {
	name: 'ZT8350',
	components: {
		PageBot,
		PageNav,
		ProdSpec
	},
	created() {
	},
	watch: {

	},

	mounted() {

	},
	data() {
		return {
			avtive_nav: "",
			screenWidth: null,
			list_status: true,
			avtive_img: 0,
			zt8350
		}
	},
	methods: {
	}
}
</script>
<style scoped lang="less">
* {
	margin: 0;
	padding: 0;
	// transition: all 1s; // 设置动画效果
}

li {
	list-style-type: none;
}

.page_1 {
	width: 100%;
	height: 100vh;
	max-height: 950px;
	object-fit: cover; // 保持图片缩放时候不变形
	background-image: url("../../assets/images/prods/zt8350/bg1.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; // 子元素无法被选中

	.page_1_center_box {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: #4D4B49;

		.cen-text {
			margin-top: 30vh;
			margin-left: 50%;
			// background-color: aqua;
			font-size: 60px;
			font-weight: 600;
		}

		.sub-text {
			margin-left: 50%;
			margin-top: 1vh;
			font-size: 28px;
		}
	}
}

.part-2 {
	display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 200px;
    background-color: #A6A6A6;
    
    h2 {
        color: #fff;
        font-weight: 400;
		line-height: 1.5;
    }
}

.part-3 {
	height: 90vh;
	background-size: cover;
	position: relative;

	div {
		color: #4D4B49;
	}

	.part-3-main {
		font-size: 55px;
		position: absolute;
		left: 12%;
		top: 32%;
	}

	.part-3-sub {
		font-size: 20px;
		position: absolute;
		left: 12%;
		top: 45%;
	}
}

.part-3-1 {
    background-image: url("../../assets/images/prods/zt8350/bg2.png");
}

.part-3-2 {
    background-image: url("../../assets/images/prods/zt8350/bg3.png");
    
    .part-3-sub {
        top: 58%;
    }
}

.part-4 {
	.part-4-head {
		margin: 90px 0;

		h1 {
			font-size: 55px;
			text-align: center;
		}

		h3 {
			font-size: 20px;
			font-weight: 400;
			text-align: center;
			margin-top: 10px;
		}
	}

	.part-4-show {
		background-image: url("../../assets/images/prods/psbg.png");
		background-size: 100% 100%;

		.screen {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 130px;
			height: 800px;

			.col-1 {
				height: 100%;
				margin-right: 20px;

				.scence {
					height: 100%;

                    img {
                        height: 100%;
                        width: 100%;
                    }
				}
			}

			.col-2 {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

                .scence {
                    height: 380px;
                    width: 600px;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
			}

			.scence {
				position: relative;

				label {
					position: absolute;
					bottom: 0;
					background-color: rgba(0, 0, 0, .9);
					height: 75px;
					color: #fff;
					width: 100%;
					font-size: 27px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

		}
	}
}

.back_up {
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	background-color: #49b1f5;

	.icon_xiangshang {
		height: 30px;
		width: 30px;
		border-radius: 30px;
	}
}

.back_up:hover {
	background-color: #49b1f5;
}
</style>
<style scoped lang="less">
// @media only screen and (min-width:1371px) {}
</style>